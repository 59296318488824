import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../layout/Layout";
import TeamMember from "../../components/TeamMember/TeamMember";

function About() {
  return (
    <>
      <Helmet>
        <title>OnnTek 404</title>
      </Helmet>
      <Layout>
        <div className="has-text-centered">
          <h1 className="is-size-3 has-text-weight-bold">Page not found</h1>
          <p>
            <img
              src="/img/illustrations/404.svg"
              style={{ maxHeight: "400px" }}
            />
          </p>
        </div>
      </Layout>
    </>
  );
}

export default About;
