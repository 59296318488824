import React from "react";
import { Helmet } from "react-helmet";
import Typed from "react-typed";
import Layout from "../../layout/Layout";

function Landing() {
  return (
    <>
      <Helmet>
        <title>OnnTek | Let us software your problems</title>
      </Helmet>
      <Layout>
        <section className="hero is-white is-halfheight">
          <div className="hero-body">
            <div className="columns">
              <div className="column is-flex is-flex-direction-column is-justify-content-center">
                <div className="is-flex is-flex-direction-column is-align-items-center is-justify-items-center">
                  <div>
                    <p className="title is-size-1">
                      Applications done
                      {/* &nbsp; */}
                      <br />
                      <Typed
                        className="has-text-primary"
                        strings={["right", "effectively", "efficiently"]}
                        typeSpeed={100}
                        backSpeed={50}
                        backDelay={1500}
                        loop
                      />
                    </p>
                    <p className="subtitle">
                      Ready to automate those redundant business tasks?
                    </p>
                    <a href="/contact">
                      <button className="button is-primary is-outlined">
                        {/* Let's do progress */}
                        Get in touch
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="column">
                <img
                  className="width-100"
                  alt="coding-illustration"
                  src="/img/illustrations/coding.svg"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="hero is-info is-halfheight">
          <div className="hero-body">
            <div className="columns">
              <div className="column is-1 is-desktop"></div>
              <div className="column is-4">
                <img
                  alt="computer-servers"
                  src="/img/illustrations/server.svg"
                />
              </div>
              <div className="column is-5 is-flex is-flex-direction-column is-justify-content-center">
                <div className="is-flex is-flex-direction-column is-align-items-center is-justify-items-center">
                  <div>
                    <p className="title is-size-3">Who We Are</p>
                    <p className="subtitle">
                      We are a team of real people who will take the time to hear you out, understand your daily tasks that take most of your valuable time. Our mission is to collaborate with cool people of all paths and backgrounds!
                    </p>
                    <a href="/about">
                      <button className="button is-primary is-outlined">
                        Meet the team
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hero is-white is-halfheight">
          <div className="hero-body">
            <div className="columns">
              <div className="column is-2 is-desktop"></div>
              <div className="column is-4 is-flex is-flex-direction-column is-justify-content-center">
                <div className="is-flex is-flex-direction-column is-align-items-center is-justify-items-center">
                  <div>
                    <p className="title is-size-3">Join the Team</p>
                    <p className="subtitle">
                      We are currently looking for talented developers who are
                      interested in getting real world experience in an agile
                      startup enviornment while working on next generation
                      projects.
                    </p>
                    <a href="/careers">
                      <button className="button is-primary is-outlined">
                        {/* Let's do progress */}
                        Apply
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="column is-5">
                <img alt="solving-puzzle" src="/img/illustrations/puzzle.svg" />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default Landing;
