import React from "react";

import "./JobBlock.css";

function JobBlock({ data }) {
  const { title, skills, link } = data;
  return (
    <div className="card" style={{ width: "380px" }}>
      <div className="card-image"></div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4 m-0">{title}</p>
          </div>
        </div>

        <div className="content">
          Skills
          <ul>
            {skills.map((s) => (
              <li>{s}</li>
            ))}
          </ul>
          <a href={link} className="button is-primary">
            Apply
          </a>
        </div>
      </div>
    </div>
  );
}

export default JobBlock;
