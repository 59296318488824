import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages
import Landing from "./pages/Landing/Landing";
import About from "./pages/About/About";
import Careers from "./pages/Careers/Careers";
import Contact from "./pages/Contact/Contact";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";

import './style/main.sass'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/about" component={About} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/contact" component={Contact} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
