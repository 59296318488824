import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

// import "./TeamMember.css";

function TeamMember({data}) {
   const { first, last, role, image, linkedin, github } = data
  return (
    <div className="card" style={{width:"380px"}} >
      <div className="card-image"></div>
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-96x96">
              <img src={image} alt={`${first}${last}`} />
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-4 m-0">
              {first} {last}
            </p>
            <p className="subtitle is-6 m-0">{role}</p>
            <p className="subtitle is-4 ">
              {linkedin && (
                <a href={linkedin} className="pr-2">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              )}
              {github && (
                <a href={github}>
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              )}
            </p>
          </div>
        </div>

        {/* <div className="content">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Phasellus nec iaculis mauris.
    </div> */}
      </div>
    </div>
    // {/*
    //   <div className="col-md-4">
    //   <div className="team-member">
    //     <img className="rounded-circle" src={image} alt={first + last} />
    //     <h4>{first}</h4>
    //     <h4 className="m-0">{last}</h4>
    //     <p className="text-muted">{role}</p>
    //     {linkedin ? (
    //       <a href={linkedin}>
    //         <FontAwesomeIcon className="team-social" icon={faLinkedin} />
    //       </a>
    //     ) : (
    //       ""
    //     )}
    //     {github ? (
    //       <>
    //         &nbsp;
    //         <a href={github}>
    //           <FontAwesomeIcon className="team-social" icon={faGithub} />
    //         </a>
    //       </>
    //     ) : (
    //       ""
    //     )}
    //   </div>
    // </div> */}
  );
}

export default TeamMember;
