import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../layout/Layout";
import TeamMember from "../../components/TeamMember/TeamMember";

function About() {
  const team = [
    {
      first: "Pier Pjerin",
      last: "Prenga",
      role: "Business Owner",
      image: "/img/team/pjerinprenga.jpeg",
      linkedin: "https://www.linkedin.com/in/pjerin-prenga-1aa25676/",
    },
    {
      first: "Justin",
      last: "Lo",
      role: "Business Partner",
      image: "/img/team/justinlo.jpeg",
      linkedin: "https://www.linkedin.com/in/justin-lo-7530091a5/",
    },
    {
      first: "Terrapin",
      last: "Christensen",
      role: "Lead Software Developer",
      image: "/img/team/terrapinchristensen.jpeg",
      linkedin: "https://www.linkedin.com/in/terrapinchristensen/",
      github: "https://github.com/TChristensenDrumz",
    },
    {
      first: "Trinity",
      last: "Chung",
      role: "Lead Designer",
      image: "/img/team/trinitychung.jpeg",
      linkedin: "https://www.linkedin.com/in/trinitychung/",
      github: "https://github.com/milotrince/",
    },
    {
      first: "Koboh",
      last: "Kosaka",
      role: "Lead Mobile Developer",
      image: "/img/team/kobohkosaka.jpg",
      linkedin: "https://www.linkedin.com/in/kokosaka/",
      github: "https://github.com/kokosaka",
    },
    {
      first: "Ethan",
      last: "Jackson",
      role: "Senior Software Developer",
      image: "/img/team/ethanjackson.jpg",
      linkedin: "https://www.linkedin.com/in/ethan-jackson-03b37b195/",
      github: "https://github.com/ethantjackson",
    },
    {
      first: "Tut",
      last: "Tongchantra",
      role: "Software Developer / Recruiter",
      image: "/img/team/tuttongchantra.jpeg",
      linkedin: "https://www.linkedin.com/in/tut-tongchantra-36b50a1b6/",
      github: "https://github.com/tuttongchan",
    },
  ];
  return (
    <>
      <Helmet>
        <title>About OnnTek</title>
      </Helmet>
      <Layout>
        <h1 className="is-size-3 has-text-weight-bold has-text-centered m-6">
          Meet the Team
        </h1>
        <div className="columns mb-6">
          <div className="column is-2 is-desktop"></div>
          <div className="column is-8 is-flex is-flex-wrap-wrap is-justify-content-center">
            {team.map((t) => (
              <div className="m-4">
                <TeamMember data={t}></TeamMember>
              </div>
            ))}
          </div>
        </div>

        <p className="has-text-centered">
          <img
            src="/img/illustrations/profile.svg"
            style={{ maxHeight: "400px" }}
          />
        </p>
      </Layout>
    </>
  );
}

export default About;
