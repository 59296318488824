import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../layout/Layout";
import JobBlock from "../../components/JobBlock/JobBlock";

function Careers() {
  const jobs = [
    {
      title: "UI/UX Developer",
      skills: ["React", "Redux", "Bootstrap", "SASS", "Material UI", "Mobile Responsiveness"],
      link: "https://www.linkedin.com/jobs/view/2658832303"
    },
    {
      title: "Mobile Developer",
      skills: ["React Native", "Redux", "Material UI"],
      link: "https://www.linkedin.com/jobs/view/2734815671"
    },
  ];

  return (
    <>
      <Helmet>
        <title>OnnTek Careers</title>
      </Helmet>
      <Layout>
        <div className="has-text-centered m-6">
          <h1 className="is-size-3 has-text-weight-bold">Careers</h1>
          <p>
            We are currently looking for individuals who are searching for real
            world experience in the positions below. 
            We conduct our hiring process through LinkedIn. Clicking the "Apply"
            button will redirect you to our LinkedIn job post for the respective
            position.
          </p>
        </div>
        <div className="columns has-centered mb-6">
          <div className="column is-flex is-flex-wrap-wrap is-justify-content-center">
            {jobs.map((j) => (
              <div className="m-4">
                <JobBlock data={j}></JobBlock>
              </div>
            ))}
          </div>
        </div>

        <p className="has-text-centered">
          <img
            src="/img/illustrations/mentor.svg"
            style={{ maxHeight: "400px" }}
          />
        </p>
      </Layout>
    </>
    // <>
    //   <Helmet>
    //     <title>OnnTek Job Board</title>
    //   </Helmet>
    //   <Layout>
    //     <div className="careers-grid">
    //       <div className="careers-title-area">
    //         <div className="careers-title">Looking to software problems?</div>
    //         <div className="careers-paragraph">
    //           We are currently looking for individuals who are searching for
    //           real world experience in the positions below. <br />
    //           We conduct our hiring process through LinkedIn, clicking the
    //           "Apply" button will redirect you to our LinkedIn job post for the
    //           respective position.
    //         </div>
    //       </div>
    //       <div className="careers-job-board">
    //         <div className="careers-web-dev">
    //           <JobBlock
    //             title="Web Developement Intern"
    //             description={webDev}
    //             link="https://www.linkedin.com/jobs/view/2660309678/?alternateChannel=search&refId=7mTm9ElfgfrC5yTz5Mq4gQ%3D%3D&trackingId=e4raShhuOJroGOQMyj5Tyw%3D%3D"
    //           />
    //         </div>
    //         <div className="careers-ui-ux">
    //           <JobBlock
    //             title="UI/UX Developer Intern"
    //             description={uiUX}
    //             link="https://www.linkedin.com/jobs/view/2643958903/?alternateChannel=search&refId=7mTm9ElfgfrC5yTz5Mq4gQ%3D%3D&trackingId=MQ9W7x8VFS%2FIsYg2faYOPQ%3D%3D"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </Layout>
    // </>
  );
}

export default Careers;
