import React, { useState } from "react";
import { Helmet } from "react-helmet";
// import emailjs from "emailjs-com";
import Layout from "../../layout/Layout";

function Contact() {
  // function sendEmail(e) {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_r427a8h",
  //       "template_58isovc",
  //       e.target,
  //       "user_K8TxidMNTIzQ5k6LkHOyR"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         alert("Your message has been sent!");
  //         document.getElementById("form").reset();
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // }

  return (
    <>
      <Helmet>
        <title>Contact OnnTek</title>
      </Helmet>
      <Layout>
        <div className="has-text-centered m-6">
          <h1 className="is-size-3 has-text-weight-bold">Contact</h1>
          <h2 className="is-size-4">
            Have an application idea for your buisness?
          </h2>
          <p>
            Tell us about your idea and we will build an interactive prototype model that will clearly show you the impact and the value we can add to your business and life for free! 
          </p>
        </div>
        <div className="columns mb-6">
          <div className="column has-text-centered">
            <a
              href="mailto:team@onntek.com"
              className="is-size-3 has-text-weight-bold"
            >
              team@onntek.com
            </a>
            <p>
              <img
                src="/img/illustrations/forms.svg"
                style={{ maxHeight: "400px" }}
              />
            </p>
          </div>
        </div>
        {/* <div className="contact-grid">
          <div className="info-area">
            <div className="text-center contact-big">
              Have an application idea for your buisness?
            </div>
            <div className="text-center contact-paragraph">
              We'd love to help make your dream application a reality!
            </div>
            <div className="text-center contact-paragraph">
              Fill out the form below with a breif description of the
              application you would like built and we'll contact you to scehdule
              a virtual meeting
            </div>
          </div>
          <div className="contact-area">
            <div className="container-fluid ml-5 contact-header">
              <div className="row mt-5 justify-content-center">
                <div className="col-sm-9">
                  <h1 className="text-center">Contact Us</h1>
                  <hr />
                  <form className="contact-form" id="form" onSubmit={sendEmail}>
                    <div className="form-group mr-5">
                      <input type="hidden" name="contact_number" />
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="user_name"
                        placeholder="Name"
                      />
                    </div>
                    <div className="form-group mr-5">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="user_email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group mr-5">
                      <label>Message</label>
                      <textarea
                        className="form-control"
                        id="contact-message"
                        name="message"
                        placeholder="Message"
                      />
                    </div>
                    <button
                      className="submit-button"
                      type="submit"
                      value="Send"
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    </>
  );
}

export default Contact;
