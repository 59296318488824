import React from "react";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";

function Layout({ children }) {
  return (
    <>
      <Nav />
      <div className="layout-grid">
        <div className="display-area">{children}</div>
        <div className="footer-area">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Layout;
