import React from "react";

function Footer() {
  return (
    <footer className="footer has-background-primary has-text-white">
      <div className="columns">
        <div className="column is-1"></div>
        <div className="column is-3">
          <img
            className="logo"
            src="/img/logo/logo-white-800x240.png"
            alt="OnnTek"
            width={200}
          />

          <p className="copyright">©2021 OnnTek</p>
        </div>

        <div className="column is-2">
          <p className="is-size-5">Connect with us</p>
          <div className="is-size-3">
            <a
              href="https://www.linkedin.com/company/onntek/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin social-icon has-text-white"></i>
            </a>
          </div>
        </div>

        <div className="column is-2">
          <p className="is-size-5">Pages</p>
          <p>
            <a href="/about" className="has-text-light">
              About
            </a>
          </p>
          <p>
            <a href="/careers" className="has-text-light">
              Careers
            </a>
          </p>
          <p>
            <a href="/contact" className="has-text-light">
              Contact
            </a>
          </p>
        </div>

      </div>
    </footer>
  );
}

export default Footer;
