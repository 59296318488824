import React from "react";

function Nav() {
  const [isActive, setActive] = React.useState(false);
  return (
    <nav className="navbar p-4" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src="/img/logo/logo-600x180.png" height="28" />
        </a>
        <a
          onClick={() => {
            setActive(!isActive);
          }}
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbar" className={`navbar-menu ${isActive && "is-active"}`}>
        <div className="navbar-start">
          <a href="/" className="navbar-item">
            Home
          </a>
          <a href="/about" className="navbar-item">
            Team
          </a>
          <a href="/careers" className="navbar-item">
            Careers
          </a>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a href="/contact" className="button is-primary">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
